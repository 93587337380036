@import "~@edgetier/client-components/dist/styles/spacing";

.anomaly-summary-value {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & + & {
        margin-top: $spacing-large;
    }

    &__value {
        margin-top: $spacing-small;
        font-weight: bold;
        font-size: 16px;
    }
}
