@import "~@edgetier/styles/sass/spacing";
@import "~@edgetier/styles/sass/colours";

.tooltip {
    $tooltip-background: $flat-midnight-blue;
    $tooltip-color: $flat-clouds;

    &__content {
        font-weight: bold;
        padding: $spacing-small;
        border-radius: 3px;
        z-index: 100000;
        background-color: $tooltip-background;
        color: $tooltip-color;
        animation: appear 250ms ease-out;
        .none {
            color: darken($tooltip-color, 20%);
        }
    }

    &__arrow {
        svg path {
            fill: $tooltip-background;
        }
    }
}
