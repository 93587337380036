@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";
@import "~@edgetier/client-components/dist/styles/variables";

.anomaly-date-filter {
    background-color: $background-tertiary;
    border: 1px solid $divider;
    border-radius: $box-border-radius;
    padding: $spacing-base;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__bars {
        display: flex;
        height: 60px;
        align-items: flex-end;
        position: relative;
        padding: $spacing-small 0;
        margin: $spacing-small 0;
    }

    &__bar {
        background-color: $label;
        border-right: 1px solid $background-tertiary;
    }

    &__range {
        background-color: $positive;
        border-radius: $box-border-radius;
        opacity: 0.6;
    }
}
