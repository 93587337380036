@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.explore-search-help {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding-right: $spacing-base;
    display: flex;
    align-items: center;

    &__tooltip {
        width: 300px;
        line-height: 18px;
        padding: $spacing-small;
        font-weight: normal;

        p:first-of-type {
            margin-top: 0;
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-left: 0;
            margin-right: 0;
            padding-left: $spacing-xx-large;
        }

        li {
            margin-top: $spacing-xxx-small;
        }

        code {
            font-size: 10px;
            font-family: Monaco, Consolas, monospace;
            background-color: rgba(black, 0.3);
            outline: 2px solid rgba(black, 0.3);
        }
    }
}
