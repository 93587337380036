$box-padding: $spacing-x-large;

.box {
    box-shadow: $box-box-shadow;
    border-radius: $box-border-radius;
    background-color: $background;

    &--outer-padding,
    &--inner-padding > :first-of-type {
        padding: $box-padding;
    }

    .react-select-container {
        border: 1px solid lighten($divider, 5%);
    }
}
