@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.explore-results {
    &,
    .anomaly-interactions {
        margin-top: $spacing-xx-large;
    }

    &__placeholder {
        text-align: center;
        font-style: italic;
        color: $label;
    }
}
