@import "~@edgetier/client-components/dist/styles/colours";

.phrase-counts-chart {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    path {
        fill: $positive;
    }

    path.phrase-counts-chart--is-highlighted {
        fill: $negative;
    }

    path.phrase-counts-chart--is-neutral {
        fill: $primary;
    }

    .yAxis tspan,
    .xAxis tspan,
    .xAxis text {
        fill: $color;
    }

    .xAxis path {
        stroke: $divider;
    }
}
