@import "date-display/date-display";
@import "date-range-display/date-range-display";
@import "fixed-date-range-input/fixed-date-range-input";
@import "relative-date-range-input/relative-date-range-input";
@import "shortcut-date-ranges/shortcut-date-ranges";

.date-filter {
    position: relative;
    overflow: hidden;
    padding-left: $inline-label-width;
    line-height: $button-height;
    cursor: pointer;

    .field-inline__icon,
    .date-filter__range-display svg {
        color: $label;
    }

    &__field {
        height: 100%;
        min-width: 0;

        > .date-filter__range-display {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__error {
        text-align: center;

        .field__error {
            position: unset;
            max-width: unset;
        }
    }

    &__input {
        cursor: unset;
        line-height: $button-height;
        animation: date-filter__select-date 150ms ease-out;
        border-radius: $spacing-x-small;
        padding: $spacing-large;
        background-color: $background;
        box-shadow: $input-box-shadow, 0 15px 40px rgba(black, 0.4);
        border: 1px solid $background-secondary;

        > .date-filter__range-display,
        > .date-filter__error {
            justify-content: center;
        }

        .date-filter__input__toggle {
            .radio {
                width: unset;
                padding: 0;

                + .radio {
                    border: none;
                }

                label {
                    display: inline-block;
                    padding: 0 $spacing-base 0 0;
                }
                svg {
                    color: get(positive);
                }
            }
        }

        .relative-date-range-input {
            border-radius: 6px;
            background-color: $background-secondary;
        }

        @keyframes date-filter__select-date {
            from {
                transform: translateY(10px);
                opacity: 0;
            }
        }
    }

    &__select-date {
        margin-bottom: $spacing-large;
        padding: $spacing-large 0;
    }

    &__select-date {
        border-radius: 6px;
        background-color: $background-secondary;
    }

    &__select-date,
    &__relative-date {
        position: relative;
    }

    &__select-date__buttons {
        display: block;
        text-align: right;
        margin-top: $spacing-large;
        padding-top: $spacing-large;
        border-top: 1px solid $background-secondary;

        .button {
            &.button--reset {
                float: left;
            }
            + .button {
                margin-left: $spacing-x-small;
            }
        }
    }

    &__relative-date {
        width: 560px;
        padding-bottom: $spacing-large;
    }
}
