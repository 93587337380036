@import "~@edgetier/client-components/dist/styles/colours";

.anomaly-date-filter-months {
    display: flex;

    &__month {
        text-align: center;
        &:not(:last-of-type) {
            border-right: 1px solid $label;
        }
    }
}
