@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";
@import "~@edgetier/client-components/dist/styles/variables";

.anomaly-date-filter-handle {
    background-color: $background;
    border-radius: $box-border-radius;
    height: 30px;
    border: 1px solid $divider;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    margin-top: -15px;
}
