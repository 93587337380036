::-webkit-scrollbar {
    width: 12px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: inherit;
}

::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
    background-color: get(scroll);
}

.field ::-webkit-scrollbar-track {
    border-left: none;
}
