.filters {
    $spacing: $spacing-base;
    margin-bottom: $filters-margin;
    display: flex;
    flex-direction: row;
    justify-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .field {
        flex: 1;
    }
    .field {
        max-width: 320px;
    }
    .field + .field,
    .field + button {
        margin-left: $spacing-base;
    }
}
