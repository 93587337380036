.client-navigation {
    height: 100%;
    text-align: center;
    background-color: $background;
    border-right: 1px solid $divider;
    display: flex;
    flex-direction: column;

    @include to(desktop) {
        flex: 0 0 75px;

        &__links a {
            word-spacing: 75px;
        }
    }

    @include at(desktop-wide) {
        flex: 0 0 90px;

        &__links a {
            word-spacing: 90px;
        }
    }

    &__links {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 var(--HEADER-HEIGHT);
        border-bottom: 1px solid $divider;

        img {
            height: 35px;
        }
    }
}
