@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.anomaly-interactions-table {
    $highlight-background: lighten($primary, 40%);
    margin-top: $spacing-base;

    &__row {
        display: flex;
        & + & {
            border-top: 1px solid $divider;
        }
    }

    &--can-fetch-more &__row:last-of-type {
        border-bottom: 1px solid $divider;
    }

    &__row > div {
        padding: $spacing-large;
    }

    .formatted-date-time {
        white-space: nowrap;
        flex: 0 0 160px;
    }

    &__content {
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    b {
        background-color: $highlight-background;
        color: $primary;
        font-weight: bold;
        outline: 2px solid $highlight-background;
    }

    &__table + .button {
        display: block;
        margin: $spacing-base auto 0 auto;
    }
}
