@import "variables";

.modal {
    margin: $spacing-x-large;
    background-color: get(background);

    &__overlay {
        background-color: rgba(black, 0.25);
        min-height: 100vh;
    }

    &__close {
        background-color: get(background);
        line-height: 40px;
    }

    form {
        background-color: get(background-secondary);
        .field {
            padding-bottom: 0;
        }
    }

    button[type="submit"] {
        width: auto;
    }

    &__overlay:not(.modal__overlay--drawer) &__content {
        padding: $modal-padding;
    }

    form {
        margin-left: -$modal-padding;
        margin-right: -$modal-padding;
        padding: $modal-padding;

        button[type="submit"],
        .button--split--submit {
            margin-top: $modal-padding * 2;
        }
    }
}

.ReactModal__Content {
    transition: transform 50ms ease-out;
}

.ReactModalPortal:not(:last-child) .ReactModal__Content {
    transform: scale(0.95);
    filter: blur(2px);
}
