.date-filter__select-date {
    line-height: initial;

    .DayPicker-Day--from:not(.DayPicker-Day--outside),
    .DayPicker-Day--to:not(.DayPicker-Day--outside) {
        background-color: $flat-green-sea !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside) {
        background-color: $flat-turquoise;
    }

    .DayPicker-Day {
        border-radius: 0 !important;
        transition: all 150ms ease-out;

        &:not(.DayPicker-Day--outside):hover {
            background-color: $flat-sunflower !important;
        }
    }

    .DayPicker-Day--today {
        color: inherit;
        font-weight: inherit;
    }

    .DayPicker-Day:focus {
        outline: none;
    }
}
