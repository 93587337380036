@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";
@import "~@edgetier/client-components/dist/styles/variables";

.proportion-toggle {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid lighten($divider, 5%);
        cursor: pointer;
        line-height: $input-height;
        box-shadow: $input-box-shadow;
        border-radius: 4px;
        padding: 0 $spacing-x-large;
    }

    svg {
        display: none;
    }

    > input {
        position: absolute;
        left: -9999px;
    }

    .react-toggle {
        margin: 0 $spacing-base;
    }

    .react-toggle-thumb {
        background-color: $background;
        border: 3px solid $label;
    }

    .react-toggle--checked .react-toggle-thumb {
        border: 3px solid $label;
        left: 21px;
    }

    .react-toggle-track {
        height: 20px;
        width: 40px;
    }

    .react-toggle-thumb {
        height: 18px;
        width: 18px;
    }

    .react-toggle-track,
    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: $label;
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
    .react-toggle--checked .react-toggle-track {
        background-color: $label;
    }

    .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}
