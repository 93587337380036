// These styles are mostly taken from the single value styles in React Select.
.react-select__custom-multi-value {
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
