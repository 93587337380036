/**
 * Styles for React Select. The library uses a JS in CSS library so everything has to be overrided with important rules.
 */

@import "variables";

.react-select {
    .field &-container {
        box-shadow: none;
        border-radius: none;
    }

    &-container {
        box-shadow: $input-box-shadow;
        border-radius: $border-radius;
        background-color: $background;
    }

    &__control {
        &,
        &--is-focussed {
            border: none !important;
            box-shadow: none !important;
            background-color: transparent !important;
        }

        &--is-disabled {
            background-color: transparent !important;
        }
    }

    &__single-value {
        line-height: $input-line-height;
    }

    &__menu {
        z-index: 999 !important;
        margin-top: 1px !important;
        border-radius: 0 !important;
        box-shadow: 0 2px 2px rgba(black, 0.1), 0 5px 2px -2px rgba(black, 0.1) !important;
        line-height: $line-height-base;
    }

    &__option {
        padding: $spacing-base $field-padding !important;
        transition: background-color 150ms ease-out, color 150ms ease-out;

        &--is-focused {
            cursor: pointer !important;

            color: $background !important;
            background-color: $positive !important;
        }

        &--is-selected {
            cursor: default !important;
            color: $positive !important;
            background-color: $background !important;
        }
    }

    &__value-container {
        padding: 0 $field-padding !important;

        > div:last-of-type {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    &__indicator-separator {
        background-color: $background-secondary;
    }

    &__multi-value {
        margin: 0 $spacing-xxx-small 0 0 !important;
        background-color: transparent !important;
        border: 1px solid $background-tertiary;

        &__label {
            padding: $spacing-x-small $spacing-small !important;
        }

        &__remove {
            transition: all 150ms ease-out !important;
            cursor: pointer;
            border-radius: 0 !important;
            color: $primary;
            border-left: 1px solid $background-tertiary;

            &:hover {
                color: $negative !important;
                background-color: $background-secondary;
            }
        }
    }
}
