.date-filter__range-display {
    display: flex;

    &__time {
        color: $flat-concrete;
        margin-left: $spacing-xxx-small;
    }

    > svg {
        margin: auto $spacing-xx-small;
    }

    > * {
        display: inline-block;
    }
}
