@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.anomaly-phrase-counts-chart-filters {
    display: flex;
    .proportion-toggle,
    .react-select-container {
        margin-left: $spacing-small;
    }
    .react-select-container {
        width: 200px;
    }
}
