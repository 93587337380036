@import "~@edgetier/styles/sass/theme";
@import "~@edgetier/styles/sass/spacing";
@import "~@edgetier/styles/sass/variables";
@import "~@edgetier/styles/sass/colours";

.markdown {
    overflow-y: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;

    > p:first-of-type {
        margin-top: 0;
    }

    ul,
    ol {
        display: block;
        list-style: disc outside none;
        margin: $spacing-base 0;
        padding: 0 0 0 $spacing-xx-large;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        display: list-item;
    }

    ul ul,
    ol ul {
        list-style-type: circle;
        margin-left: $spacing-base;
    }

    ol ol,
    ul ol {
        list-style-type: lower-latin;
        margin-left: $spacing-base;
    }

    hr {
        height: 1px;
        border: 0;
        @include theme {
            border-bottom: 2px solid get(divider);
        }
    }

    a {
        $link-colour: $flat-peter-river;
        color: $link-colour;
        text-decoration: underline;
        transition: color 250ms;
        &:hover {
            color: darken($link-colour, 20%);
        }
    }

    pre,
    code {
        font-family: Monaco, Consolas, monospace;
        font-size: $font-size-base;
        @include theme {
            background-color: get(background-tertiary);
        }
    }

    blockquote {
        @include theme {
            background-color: get(background-secondary);
            border-left: 3px solid get(divider);
        }
    }

    pre {
        line-height: $line-height-base;
    }

    pre,
    blockquote {
        padding: $spacing-base;
        margin: $spacing-base 0;
        p {
            margin: 0;
        }
    }

    img {
        max-height: 200px;
    }

    em {
        font-style: italic;
    }
}
