$successBgColor: $flat-green-sea;
$infoBgColor: $flat-belize-hole;
$warningBgColor: $flat-carrot;
$errorBgColor: $flat-alizarin;

@import "~react-redux-toastr/src/styles/index";

.redux-toastr .toastr {
    $icon-width: 60px;
    $line-height: 18px;
    min-height: $line-height * 2;
    border-radius: 2px;
    .rrt-middle-container {
        margin-left: $icon-width + $spacing-base;
        .rrt-title,
        .rrt-text {
            line-height: $line-height;
        }
    }
    .rrt-left-container,
    .rrt-left-container .rrt-holder {
        width: $icon-width;
    }
    .close-toastr {
        color: white;
        font-size: $font-size-base;
    }
}
