.page-content {
    flex: 1;
    padding: $page-spacing;
    overflow-y: auto;

    &__container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    &__container--standard {
        width: 1200px;
    }
}
