@keyframes enter {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }
    100% {
        transform: translateY(0);
    }
}

.client-login {
    position: absolute;
    animation: enter 100ms ease-in;
    top: 25%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    opacity: 1;
    text-align: center;
    color: $flat-midnight-blue;

    h1 {
        margin-bottom: $spacing-xx-large;
    }

    img {
        height: 22px;
        margin-bottom: $spacing-xx-large;
    }

    .button {
        display: block;
        width: 100%;
    }

    .button + .button {
        margin-top: $spacing-small;
    }

    &__background {
        height: calc(25% + 50px);
        position: fixed;
        background-position: center;
        background-size: contain;
        background-color: $flat-peter-river;
        width: 100%;
        background-repeat: no-repeat;
    }

    &__container {
        padding: $spacing-xxx-large;
        background-color: white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 -20px 100px rgba($flat-peter-river, 0.1);
        border-radius: 3px;
        border-bottom: 1px solid $flat-silver;
    }

    &__version {
        color: $label;
        margin-top: $spacing-small;
    }
}
