@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.anomaly {
    &__summary {
        display: flex;
        margin: $spacing-xx-large 0;
    }

    .page-header .button {
        margin-left: auto;
    }

    .page-header h2 {
        text-transform: capitalize;
    }

    .anomaly__metrics {
        flex: 0 0 220px;
        margin-right: $spacing-large;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
