/**
 * Node dependencies. Provide mixins to subsequent imports.
 */
@import "~jeet/jeet";

@import "~@edgetier/client-components/styles";
@import "~@edgetier/components/styles";
@import "~react-toggle/style";

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
#root {
    height: 100%;
}
