.react-select__option {
    display: flex !important;
    justify-content: space-between;

    &--is-selected svg {
        color: $positive;
    }

    &--is-selected:hover svg {
        color: $negative;
    }
}
