.navigation-link {
    flex: 1;
    max-height: 100px;

    &__inner {
        cursor: pointer;
        height: 100%;
        border-bottom: 1px solid $background-tertiary;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: $font-size-small;
        line-height: 1.3rem;
    }

    svg {
        font-size: 16px;
        display: block;
        margin-bottom: $spacing-xxx-small;
        transition: color 150ms ease-in;
    }

    a svg {
        color: $primary;
    }
}
