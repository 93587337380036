.date-filter__shortcut-ranges {
    display: flex;
    justify-content: center;
    padding: $spacing-large 0;
    border-top: 1px solid $background-secondary;

    .button {
        flex-basis: calc(#{percentage(1 / 4)} - #{$spacing-x-small * 2});

        + .button {
            margin-left: $spacing-base;
        }
    }
}
