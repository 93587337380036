@mixin outline-button($background-color) {
    &:not(.button-disabled):not([disabled]) {
        background-color: transparent;
        color: $background-color;
        border: 1px solid rgba($background-color, 0.6);
        &:hover {
            color: white;
            background-color: $background-color;
        }
        &:active {
            background-color: $color;
        }
    }
    &[data-tooltip],
    &[data-tooltip-left] {
        @keyframes tooltip-appear {
            from {
                transform: scale(0.25);
            }
            to {
                visibility: visible;
            }
        }
        position: relative;
        &:hover:before {
            animation: tooltip-appear 100ms ease-in forwards;
            animation-delay: 750ms;
        }
        &:before {
            display: flex;
            align-items: center;
            visibility: hidden;
            position: absolute;
            font-size: $font-size-small;
            white-space: nowrap;
            z-index: 100;
            text-transform: none;
            padding: 0 $spacing-base;
            top: -1px;
            bottom: -1px;
            color: inherit;
            border: inherit;
            background-color: inherit;
            box-shadow: inherit;
        }
    }
    &[data-tooltip] {
        &:before {
            content: attr(data-tooltip);
            transform-origin: left center;
            left: 100%;
        }
    }
    &[data-tooltip-left] {
        &:before {
            content: attr(data-tooltip-left);
            transform-origin: right center;
            right: 100%;
        }
    }
}

@mixin button($background-color, $background-color-hover) {
    &:not(.button-disabled):not([disabled]) {
        background-color: $background-color;
    }
    &:not(.button-disabled):not([disabled]):hover {
        background-color: $background-color-hover;
        cursor: pointer;
    }
}

.button {
    @include button($primary, $primary-variant);
    display: inline-block;
    color: white;
    font-weight: bold;
    line-height: $button-height;
    padding-left: $spacing-base;
    padding-right: $spacing-base;
    box-shadow: $input-box-shadow;
    transition: all 0.25s linear;
    text-transform: uppercase;
    font-size: $font-size-small;
    text-align: center;
    border-radius: $border-radius;
    cursor: pointer;

    &[disabled],
    &-disabled {
        cursor: not-allowed;
        box-shadow: none;
        background-color: darken($disabled, 10%);
        color: darken($disabled-color, 10%);
    }

    &--outline[disabled],
    &--outline.button-disabled {
        background-color: transparent;
        color: lighten($flat-concrete, 25%);
        border: 1px solid rgba($flat-concrete, 0.2);
    }

    &:not([disabled]):not(&-disabled):hover:active {
        transition: none;
        box-shadow: none;
        background-color: $color;
        border-color: $color;
    }

    i,
    svg {
        font-weight: normal;
        margin-right: $spacing-base;
    }

    &--positive {
        @include button(positive, positive-variant);
    }

    &--negative {
        @include button(negative, negative-variant);
    }

    &--neutral {
        @include button(neutral, neutral-variant);
    }

    // Account for border width.
    &--outline {
        line-height: $button-height - 2px;
    }

    &--icon {
        text-align: center;
        width: $button-height;
        padding: 0;
        font-size: 18px;
        i,
        svg {
            margin-right: 0;
        }
    }

    &--small {
        line-height: $button-small-height;
    }

    &--small.button--icon {
        width: $button-small-height;
        font-size: $font-size-small;
    }
}

.button--outline {
    @include outline-button($primary);
}

.button--positive.button--outline {
    @include outline-button($positive);
}

.button--negative.button--outline {
    @include outline-button($negative);
}

.button--neutral.button--outline {
    @include outline-button($neutral);
}

button {
    border: none;
    height: $button-height;

    &.button--small {
        height: $button-small-height;
    }

    padding-top: 0;
    padding-bottom: 0;
    &:focus {
        outline: none;
    }
}

tbody .button--outline,
tbody button {
    height: $table-button-height;
    line-height: $table-button-height;
}
