@import "_colours";

$themes: (
    dark: (
        color: $flat-clouds,
        color-secondary: white,
        background: $flat-wet-asphalt,
        background-secondary: darken($flat-wet-asphalt, 2%),
        background-tertiary: $flat-midnight-blue,
        divider: darken($flat-wet-asphalt, 7%),
        label: $flat-concrete,
        medium: $flat-silver,
        pop-up-background: $flat-midnight-blue,
        pop-up-color: $flat-clouds,
        scroll: darken($flat-midnight-blue, 5%),
        modal-overlay-background:
            linear-gradient(
                to top,
                rgba(darken($flat-midnight-blue, 10%), 0.2),
                rgba(darken($flat-midnight-blue, 25%), 0.5)
            ),
        loading-background: white,
        primary: $flat-peter-river,
        primary-variant: $flat-belize-hole,
        positive: $flat-green-sea,
        positive-variant: darken($flat-green-sea, 5%),
        negative: $flat-alizarin,
        negative-variant: $flat-pomegranate,
        neutral: $flat-asbestos,
        neutral-variant: darken($flat-asbestos, 10%),
        disabled: darken($flat-midnight-blue, 5%),
        disabled-color: lighten($flat-wet-asphalt, 10%),
        alert-background: $flat-pomegranate,
        alert-color: white,
        alert-border: $flat-alizarin,
    ),
    light: (
        color: $flat-midnight-blue,
        color-secondary: $flat-wet-asphalt,
        background: white,
        background-secondary: lighten($flat-clouds, 2%),
        background-tertiary: $flat-clouds,
        divider: darken($flat-clouds, 7%),
        label: $flat-asbestos,
        medium: $flat-concrete,
        pop-up-background: $flat-wet-asphalt,
        pop-up-color: white,
        scroll: $flat-concrete,
        modal-overlay-background: linear-gradient(to top, rgba($flat-clouds, 0.2), rgba($flat-midnight-blue, 0.5)),
        loading-background: $flat-alizarin,
        primary: $flat-peter-river,
        primary-variant: $flat-belize-hole,
        positive: $flat-green-sea,
        positive-variant: darken($flat-green-sea, 5%),
        negative: $flat-alizarin,
        negative-variant: $flat-pomegranate,
        neutral: $flat-asbestos,
        neutral-variant: darken($flat-asbestos, 10%),
        disabled: $flat-clouds,
        disabled-color: $flat-silver,
        alert-background: lighten($flat-alizarin, 37%),
        alert-color: $flat-alizarin,
        alert-border: $flat-alizarin,
    ),
    contrast: (
        color: black,
        color-secondary: $flat-midnight-blue,
        background: white,
        background-secondary: lighten($flat-silver, 2%),
        background-tertiary: darken($flat-silver, 2%),
        divider: $flat-silver,
        label: darken($flat-asbestos, 15%),
        medium: darken($flat-asbestos, 5%),
        pop-up-background: $flat-wet-asphalt,
        pop-up-color: white,
        scroll: $flat-concrete,
        modal-overlay-background: linear-gradient(to top, rgba($flat-clouds, 0.2), rgba($flat-midnight-blue, 0.5)),
        loading-background: $flat-alizarin,
        primary: $flat-peter-river,
        primary-variant: $flat-belize-hole,
        positive: $flat-green-sea,
        positive-variant: darken($flat-green-sea, 5%),
        negative: $flat-alizarin,
        negative-variant: $flat-pomegranate,
        neutral: $flat-asbestos,
        neutral-variant: darken($flat-asbestos, 10%),
        disabled: $flat-concrete,
        disabled-color: $flat-clouds,
        alert-background: lighten($flat-alizarin, 40%),
        alert-color: $flat-alizarin,
        alert-border: $flat-alizarin,
    ),
);

@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);

    @if $value != null {
        @if $length > 1 {
            $rest: ();

            @for $i from 2 through $length {
                $rest: append($rest, nth($keys, $i));
            }

            @return map-fetch($value, $rest);
        } @else {
            @return $value;
        }
    } @else {
        @return false;
    }
}

@function get($key) {
    @return map-get($theme-map, $key);
}

@mixin theme($themes: $themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            // Define theme color
            $theme-map: (
                color-alpha: blue,
            ) !global;
            @each $key, $submap in $map {
                $value: map-fetch($themes, $theme "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}
