@import "~@edgetier/client-components/dist/styles";
@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";
@import "~@edgetier/client-components/dist/styles/variables";

.anomaly-summary {
    $padding: $spacing-large;
    $height: 140px;

    margin-top: $spacing-large;
    height: $height;

    a {
        display: flex;
    }

    p,
    .anomaly-description,
    &__model-name {
        margin-top: $spacing-small;
    }

    &__titles,
    .anomaly-description,
    &__model-name {
        padding-left: $spacing-base;
        padding-right: $spacing-base;
    }

    &__model-name {
        color: $primary;
    }

    &__details,
    &__chart {
        flex: 1;
    }

    &__chart {
        margin-left: $spacing-large;
        background-color: $background-secondary;
        border-radius: $box-border-radius;
        padding: $spacing-base $spacing-xx-large;
        height: $height - (2 * $box-padding);
    }
}
