@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.phrase-counts-chart-tooltip {
    background-color: $pop-up-background;
    color: $background;
    padding: $spacing-large;
    border-radius: 4px;
    box-shadow: 0 2px 1px rgba(black, 0.2);
    text-align: center;

    &__time {
        margin-top: $spacing-xxx-small;
    }

    &__value {
        font-size: 18px;
        font-weight: bold;
        margin: $spacing-small 0;
    }
}
