@import "~@edgetier/client-components/dist/styles/spacing";

.anomaly-phrase-counts-chart {
    flex: 1;

    &__chart {
        height: 300px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-x-large;
    }
}
