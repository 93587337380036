@import "~@edgetier/client-components/dist/styles/colours";
@import "~@edgetier/client-components/dist/styles/spacing";

.explore-filters {
    display: flex;
    justify-content: center;

    .field {
        background-color: $background;
        flex: 1;
    }

    .field:first-of-type {
        position: relative;
    }

    .field {
        margin-right: $spacing-base;
    }
}
