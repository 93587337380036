.page-header {
    padding-left: $page-spacing;
    padding-right: $page-spacing;
    border-bottom: 1px solid $divider;
    display: flex;
    width: 100%;
    align-items: center;
    height: var(--HEADER-HEIGHT);
    h2 {
        float: left;
    }
}