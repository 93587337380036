.relative-date-range-input {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    grid-gap: $spacing-xx-small;
    padding: $spacing-large;

    &__label {
        display: flex;
        align-items: center;
    }

    .field-inline input[type="number"] {
        padding-left: $spacing-large;
        text-align: right;
    }
}
