.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    z-index: 999;
    background-color: get(loading-background);
    box-shadow: 1px 0 3px get(loading-background);
}
