.reporting {
    padding-bottom: 0;
    &-chart {
        h3 {
            line-height: $reporting-chart-title-height;
            padding-left: $reporting-card-padding;
        }

        h3 span {
            font-weight: normal;
            color: $flat-asbestos;
        }
    }

    &-chart__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: $reporting-card-padding;

        .field-inline {
            width: 200px;
            border: 1px solid get(background-tertiary);
        }

        .field-inline + .field-inline {
            margin-left: $spacing-base;
        }

        form {
            display: flex;
        }
    }

    &-chart,
    &-card {
        border-radius: $border-radius;
        background-color: get(background);
    }

    &-card-large {
        height: $reporting-card-height-large;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .reporting-card__value {
            font-size: 22px;
            padding: $spacing-base 0;
            font-weight: bold;
        }

        > svg,
        i,
        span {
            font-size: 18px;
        }

        span {
            color: get(label);
        }
    }

    &-card {
        padding: $reporting-card-padding;

        i,
        > svg {
            color: get(primary);
        }
    }

    &-chart-container {
        height: calc(100% - #{$reporting-chart-title-height});

        .spinner {
            height: 100%;
        }
    }

    &-chart,
    &-card {
        box-shadow: $reporting-card-box-shadow;
    }
}

.recharts-cartesian-axis-ticks text,
.recharts-label {
    fill: get(color);
}

.recharts-label {
    font-weight: bold;
}

.recharts-cartesian-axis-line {
    stroke: get(divider);
}

.recharts-cartesian-axis-tick-line {
    stroke: none;
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
    stroke: get(divider);
}

.recharts-bar-rectangle:hover path {
    fill: get(pop-up-background);
}

.recharts-rectangle.recharts-tooltip-cursor {
    opacity: 0.25;
    fill: get(pop-up-background);
}

.chart-tooltip {
    padding: $spacing-large;
    border-radius: 2px;
    box-shadow: 0 2px 2px rgba(black, 0.25);
    display: flex;
    align-items: center;
    color: get(pop-up-color);
    background-color: get(pop-up-background);

    div {
        display: inline-block;
        line-height: $line-height-base;
    }

    &-value {
        font-size: 2rem;
        font-weight: bold;
        padding-right: $spacing-base;
    }

    &-label {
        max-width: 130px;
        border-left: 1px dashed rgba(white, 0.2);
        padding-left: $spacing-base;
    }

    &--multiple {
        text-align: center;

        .chart-tooltip-value div {
            display: block;
            font-size: $font-size-small;
            padding-bottom: $spacing-xxx-small;
        }

        .chart-tooltip-value div div {
            display: inline-block;
            margin-left: $spacing-xxx-small;
        }
    }
}

.recharts-bar + .recharts-bar {
    .recharts-bar-rectangle {
        stroke: white;
        stroke-width: 1px;
    }
}
